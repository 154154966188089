<template>
  <div>
    <strong>Switch html view:</strong><br/>
    <input
        :id="`switch_${index}`"
        :checked="showHtml"
        class="toggleSwitch"
        type="checkbox"
        @change="handleToggleChange($event, tp)"
    /><label :for="`switch_${index}`" class="toggleLabel"></label>
    <hr/>
    <strong>From:</strong> {{ item.from_email }}<br/>
    <strong>Received:</strong> {{ formatReadDateTime(item.created_at) }}<br/>
    <strong>Subject:</strong> {{ item.subject }}
    <hr/>
    <EmailAttachment
        v-if="hasAttachment.result"
        :attachments="emailAttachment"
    />
    <div v-if="showHtml">
      <div v-if="item.html_content" v-html="sanitizeHTML(item.html_content)"/>
      <div v-else>
        <iframe
            v-show="emailReady"
            id="emailIframe"
            ref="emailIframe"
            class="iframe-wrap"
            frameborder="0"
            style="background: #FFFFFF;"
        ></iframe>
      </div>
    </div>
    <div v-else>
      {{ item.content || "no plain text available" }}
    </div>
    <hr/>
    <div v-if="allowReply" class="reply-button">
      <button
          v-if="item.type === 'inbound'"
          class="btn btn-success"
          @click="handleReply(item.from_email)"
      >
        Reply
      </button>
    </div>
    <div v-if="allowReply" class="reply-button -bottom">
      <button
          v-if="item.type === 'inbound'"
          class="btn btn-success"
          @click="handleReply(item.from_email)"
      >
        Reply
      </button>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import EmailAttachment from "@/components/email-manager/EmailAttachment";

export default {
  name: "EmailViewer",
  props: {
    index: {
      default: null
    },
    item: {
      default: null
    },
    allowReply: {
      default: true
    },
    parent: {
      type: String,
      default: 'tp'
    },
  },
  data: () => ({
    showHtml: true,
    emailReady: false,
    emailAttachment: null
  }),
  components: {
    EmailAttachment
  },
  computed: {
    hasAttachment() {
      if (this.item.attachments == null) return false;
      if (this.item.attachments.length === 0) return false;
      return {
        result: true,
        value: this.item.attachments
      };
    }
  },
  methods: {
    ...mapActions(["fetchEmailAttachments", "previewEmail"]),
    async renderEmail() {
      let response = await this.previewEmail({
        activeTemplate: this.item.email_template,
        tpuuid: this.$route.params.uuid,
        data: JSON.parse(this.item.contents) || {}
      });
      if (response.status === 200) {
        this.emailReady = true;
        this.$refs.emailIframe.srcdoc = response.data.data.body;
      }
    },
    handleToggleChange() {
      this.showHtml = !this.showHtml;
      if (this.showHtml) {
        if (this.emailIframeContent) {
          this.loadEmailIframe();
        }
        if (this.item.email_template) {
          this.renderEmail();
        }
      }
    },
    handleReply(replyEmail) {
      let queryObj = {}
      if (this.parent === 'tp') {
        queryObj = {
          query: {
            onlyBlankTemplate: false,
            replyEmail: replyEmail,
            parent: this.$route.query.parent
          }
        }
      } else {
        queryObj = {
          query: {
            parent: this.parent || this.$route.query.parent
          }
        }
      }

      this.$router.push({
        name: "Email Send Interface",
        params: {
          tpuuid: this.$route.params.tpuuid || this.$route.params.uuid
        },
        ...queryObj
      });
    },
    // sanitizeHTML(html) {
    //   const tempDiv = document.createElement('div');
    //   tempDiv.textContent = html;
    //   return tempDiv.innerHTML;
    // },
  },
  async beforeMount() {
    let uuid = this.$route.params.uuid;
    let attachments = this.item.attachments;
    let response = await this.fetchEmailAttachments({
      uuid,
      attachments
    }).catch(err => console.log(err));

    if (response) {
      this.emailAttachment = response.data.result;
    }
  },
  mounted() {
    // if (typeof this.item.type === "undefined") {
    this.loadEmailIframe();
    // this.showHtml = true;
    // }
  }
};
</script>
<style lang="scss" scoped>
.iframe-wrap {
  width: 600px;
  height: 60vh;
}

.reply-button {
  position: absolute;
  top: $spacingBase;
  right: $spacingBase;

  &.-bottom {
    position: relative;
    top: initial;
    right: initial;
    text-align: right;
  }
}
</style>
